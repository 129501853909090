import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import type {
  BillingPlan,
  SubscriptionAddonsType,
  SubscriptionLimits,
  SubscriptionSliceType,
  SubscriptionStatus,
} from './subscriptionSliceTypes'
import {
  SUBSCRIPTION_FREE,
  trialAgencySubscriptionFactory,
  extendTrialSubscriptionFactory,
  SubscriptionType,
  SUBSCRIPTION_PRO,
  SUBSCRIPTION_AGENCY,
} from './subscriptionConstants'
export * from './subscriptionSelectors'
export * from './subscriptionSliceMemoizedSelectorHooks'

export const initialState: SubscriptionSliceType = {
  status: 'idle',
  error: null,
  entity: null,
  limits: {
    hasExceededGlobalLimit: false,
    hasReachedGlobalLimit: false,
    exceeded: {
      projects: false,
      pages: false,
      aiActions: false,
    },
    reached: {
      projects: false,
      pages: false,
      aiActions: false,
    },
    maxPageHistoryDays: 0,
  },
  triggerLimitsCheck: {
    timestamp: null,
    type: undefined,
  }, // @author @michalpalenkar
} as SubscriptionSliceType

// ---------------
// Reducer
// ---------------

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: () => initialState,
  reducers: {
    updateSubscription: (
      state,
      action: PayloadAction<{
        subscription: NonNullable<SubscriptionSliceType['entity']>
        subscriptionAddons: SubscriptionAddonsType
      }>,
    ) => {
      state.status = 'succeeded'

      const { subscription, subscriptionAddons } = action.payload

      // TODO whatchout for API typo in oc[c]upied
      const {
        availableFree: seatsFreeAvailable,
        availablePaid: seatsPaidAvailable,
        free: seatsFree,
        ocupiedAll: seatsAllOccupied,
        ocupiedFree: seatsFreeOccupied,
        ocupiedPaid: seatsPaidOccupied,
        paid: seatsPaid,
      } = subscriptionAddons?.seats || {}

      const {
        availableFree: sitePlansFreeAvailable,
        availablePaid: sitePlansPaidAvailable,
        free: sitePlansFree,
        ocupiedAll: sitePlansAllOccupied,
        ocupiedFree: sitePlansFreeOccupied,
        ocupiedPaid: sitePlansPaidOccupied,
        paid: sitePlansPaid,
      } = subscriptionAddons?.sitePlans || {}

      state.entity = {
        ...(state.entity || {}),
        ...subscription,
        seatsFreeAvailable,
        seatsPaidAvailable,
        seatsFree,
        seatsAllOccupied,
        seatsFreeOccupied,
        seatsPaidOccupied,
        seatsPaid,
        sitePlansFreeAvailable,
        sitePlansPaidAvailable,
        sitePlansFree,
        sitePlansPaid,
        sitePlansAllOccupied,
        sitePlansFreeOccupied,
        sitePlansPaidOccupied,
      }
    },
    wsUpdateSubscription: (
      state,
      action: PayloadAction<NonNullable<SubscriptionSliceType['entity']>>,
    ) => {
      state.status = 'succeeded'

      state.entity = {
        ...(state.entity || {}),
        ...action.payload,
      }
    },
    activateTrial: (state) => {
      if (state.status !== 'succeeded') {
        return
      }
      state.entity = {
        ...(state.entity || {}),
        ...trialAgencySubscriptionFactory(),
      }
      // Recalculate limits
      state.triggerLimitsCheck = {
        timestamp: new Date().getTime(),
        type: 'redux',
      }
    },
    extendTrial: (state) => {
      if (state.status !== 'succeeded') {
        return
      }
      state.entity = {
        ...(state.entity || {}),
        ...extendTrialSubscriptionFactory({
          currentTrialExtensions: state.entity.currentTrialExtensions,
          trialEndAt: state.entity.trialEndAt,
        }),
      }
      // Recalculate limits
      state.triggerLimitsCheck = {
        timestamp: new Date().getTime(),
        type: 'redux',
      }
    },
    activateSubscription: (state, action: PayloadAction<BillingPlan>) => {
      if (state.status !== 'succeeded') {
        return
      }

      let subscriptionPreset: SubscriptionType | null = null

      if (action.payload === 'free') {
        subscriptionPreset = SUBSCRIPTION_FREE
      }

      if (action.payload === 'proMonth') {
        subscriptionPreset = SUBSCRIPTION_PRO
      }

      if (action.payload === 'agencyMonth') {
        subscriptionPreset = SUBSCRIPTION_AGENCY
      }

      if (!subscriptionPreset) {
        return
      }

      const currentPlanStatus = state.entity.currentPlanStatus
      state.entity = {
        ...(state.entity || {}),
        ...subscriptionPreset,
        currentPlanStatus: currentPlanStatus || 'active',
      }
      // Recalculate limits
      state.triggerLimitsCheck = {
        timestamp: new Date().getTime(),
        type: 'redux',
      }
    },
    updateSubscriptionCurrentPlanStatus: (
      state,
      action: PayloadAction<SubscriptionStatus>,
    ) => {
      if (state.status !== 'succeeded') {
        return
      }
      state.entity.currentPlanStatus = action.payload
    },
    updateSubscriptionLimits: (
      state,
      action: PayloadAction<SubscriptionLimits>,
    ) => {
      state.limits = action.payload
      state.triggerLimitsCheck.type = undefined
    },
    resetSubscriptionLimits: (state) => {
      state.limits = initialState.limits
      state.triggerLimitsCheck.type = undefined
    },
    setTriggerLimitsCheck: (
      state,
      action: PayloadAction<'redux' | 'remote'>,
    ) => {
      state.triggerLimitsCheck = {
        timestamp: new Date().getTime(),
        type: action.payload,
      }
    },
    updateAddons: (state, action: PayloadAction<SubscriptionAddonsType>) => {
      if (state.status !== 'succeeded') {
        return
      }

      const {
        availableFree: seatsFreeAvailable,
        availablePaid: seatsPaidAvailable,
        free: seatsFree,
        ocupiedAll: seatsAllOccupied,
        ocupiedFree: seatsFreeOccupied,
        ocupiedPaid: seatsPaidOccupied,
        paid: seatsPaid,
      } = action.payload?.seats || {}

      const {
        availableFree: sitePlansFreeAvailable,
        availablePaid: sitePlansPaidAvailable,
        free: sitePlansFree,
        ocupiedAll: sitePlansAllOccupied,
        ocupiedFree: sitePlansFreeOccupied,
        ocupiedPaid: sitePlansPaidOccupied,
        paid: sitePlansPaid,
      } = action.payload?.sitePlans || {}

      state.entity = {
        ...state.entity,
        seatsFreeAvailable,
        seatsPaidAvailable,
        seatsFree,
        seatsAllOccupied,
        seatsFreeOccupied,
        seatsPaidOccupied,
        seatsPaid,
        sitePlansFreeAvailable,
        sitePlansPaidAvailable,
        sitePlansFree,
        sitePlansAllOccupied,
        sitePlansFreeOccupied,
        sitePlansPaidOccupied,
        sitePlansPaid,
      }
    },
  },
  extraReducers(builder) {
    builder.addCase('USER:LOGOUT', () => {
      return initialState
    })
  },
})

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useSubscriptionDispatch: () => AppDispatch = useDispatch
export const useSubscriptionSelector: TypedUseSelectorHook<RootState> =
  useSelector

export const {
  wsUpdateSubscription,
  updateSubscription,
  activateTrial,
  activateSubscription,
  updateSubscriptionCurrentPlanStatus,
  updateSubscriptionLimits,
  setTriggerLimitsCheck,
  extendTrial,
  resetSubscriptionLimits,
  updateAddons,
} = subscriptionSlice.actions

export default subscriptionSlice.reducer
