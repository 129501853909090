export const data = {
  "aclallowedresourcesquerydocument": {
    "hash": "a82e3cb756b802e5a305700065cddbfa12d4cdf2f0b6940cccfe0cb00ead21b3:3.17.3",
    "operation": "query"
  },
  "aclrolesresourcesquerydocument": {
    "hash": "2659ede291deccf35495fed40af0b7a9d63d685d5adcbc079a2150dc3253ab9b:3.17.3",
    "operation": "query"
  },
  "acluserpermissionsquerydocument": {
    "hash": "81f91b45511bb22d4b0147b2620732b49c003e451e208adce7f879cd2790ba5f:3.17.3",
    "operation": "query"
  },
  "anonymousquerydocument": {
    "hash": "0ecfe1a851504890cb74bca9f2058a5467f528f7c3438595a7c6eb697f4d1fec:3.17.3",
    "operation": "query"
  },
  "assetdeletemutationdocument": {
    "hash": "cbba3c0ee28b6243571bf0ec8e1cd145a8db91af7490557694689ee6cac910e8:3.17.3",
    "operation": "mutation"
  },
  "assetrenamemutationdocument": {
    "hash": "565fee0a23f9e159f922016d5c8823e8f1852498da83b1160d8e994fa7dafe8d:3.17.3",
    "operation": "mutation"
  },
  "assetsquerydocument": {
    "hash": "0bf188c759b0df0ee53d3eb6ea9b5a669213775831d44f017dffb08f78338f48:3.17.3",
    "operation": "query"
  },
  "assignreusablecontentblockdocument": {
    "hash": "2e391251e22aa471d021466d2688058a6f80ba10f81902f443fbe6ae0ea7bc0f:3.17.3",
    "operation": "mutation"
  },
  "attributespathassignmentquerydocument": {
    "hash": "cb93b0f1bb3f0aa5a712e102d9c598f8412db63a5bbed2b1b7e394679cd44845:3.17.3",
    "operation": "query"
  },
  "changecontentpublishstatemutationdocument": {
    "hash": "cd415163eda0d08daf6a4a281025d27090c4fd478c8313daebf6de875f49b9a9:3.17.3",
    "operation": "mutation"
  },
  "checkdomainavailabilitydocument": {
    "hash": "0a76043508fc4b892a333faea7274dd3cb78e9cd07deb00c5dbbe140ac691560:3.17.3",
    "operation": "query"
  },
  "commandsubscriptiondocument": {
    "hash": "7d16543a288027828e236446f0c8a6c0732ad21812c0e8f6ea95bacc2852bd22:3.17.3",
    "operation": "subscription"
  },
  "commentcreatedsubscriptiondocument": {
    "hash": "ddb1e5f6680e7a2192552964681cb7318d06a5831f26d7f77a84161287c2787c:3.17.3",
    "operation": "subscription"
  },
  "commentcreatemutationdocument": {
    "hash": "cce3fb208bde461df2a2bbe4242d2229bec26f43d2c6f2804726c4c5edc4b3cf:3.17.3",
    "operation": "mutation"
  },
  "commentdeletedsubscriptiondocument": {
    "hash": "98d4fb01345fe4d36dcf884e0a310f5b1ccf7c53f162e10b0815d6732fa75908:3.17.3",
    "operation": "subscription"
  },
  "commentrepliedsubscriptiondocument": {
    "hash": "3b2a295510d9154c7a2d0bf56527184f8ce13603ff478e2f88e8962aaa19700b:3.17.3",
    "operation": "subscription"
  },
  "commentreplytomutationdocument": {
    "hash": "286106aa6aa770f53be9a952d73faabd7e1e359b699bb9ac8e5e86e8fda6c6d9:3.17.3",
    "operation": "mutation"
  },
  "commentresolvedsubscriptiondocument": {
    "hash": "24fac09246b2e83eab137eb199508116a50ec5e393a1be8f4829a9b3ca67091b:3.17.3",
    "operation": "subscription"
  },
  "commentsquerydocument": {
    "hash": "0d56e618283f4898b8cc8446b1da02ddfd65ac584d919c3413b25febad35463d:3.17.3",
    "operation": "query"
  },
  "commentupdatedsubscriptiondocument": {
    "hash": "44dd3c881d9929e9b26620f862734466b47eb74aec30de14b524d47bb12bdab1:3.17.3",
    "operation": "subscription"
  },
  "commentupdatemutationdocument": {
    "hash": "ed1684ce5e2243936945a58a1be94746b594aa284605db772c72bdde66e94f38:3.17.3",
    "operation": "mutation"
  },
  "contentauditquerydocument": {
    "hash": "ed5e9fd81f73155c10478868a871b30c9be99c9336cd95a096a4781ebbf6959d:3.17.3",
    "operation": "query"
  },
  "contentblockaddmutationdocument": {
    "hash": "03434da4ebb8263a156ba085a3ca2c455ec5dcd20f77b487636efcc2f762742a:3.17.3",
    "operation": "mutation"
  },
  "contentblockdeletemutationdocument": {
    "hash": "98f472272c3f22433828ef34e5a35acbb490a45bdd90461c77e587eb455dda96:3.17.3",
    "operation": "mutation"
  },
  "contentblockduplicatemutationdocument": {
    "hash": "b75485905567e2f26380588a5419898885cbd7debe9f2f827c44537f15d3d735:3.17.3",
    "operation": "mutation"
  },
  "contentblockorderupdatemutationdocument": {
    "hash": "c92746aa1c80143e95955e0c1c6e806e19c36cf87b3ab65cc30c0ee511175c9f:3.17.3",
    "operation": "mutation"
  },
  "contentblocksaddmutationdocument": {
    "hash": "6f4bbe7b1a76428adeb71ae9f37855d1f6504240a93da5ff2d044dd028a88c21:3.17.3",
    "operation": "mutation"
  },
  "contentblockscreatedsubscriptiondocument": {
    "hash": "8a18206b768897c4f4da2a90ad8339a469575babd331db2e95a158104d5f5575:3.17.3",
    "operation": "subscription"
  },
  "contentblocksdeletedsubscriptiondocument": {
    "hash": "8a9141e42e246d7b5eb1c77066bfca1917bf03ae7c4058a506d49d0961196402:3.17.3",
    "operation": "subscription"
  },
  "contentblocksdeletemutationdocument": {
    "hash": "2d244adf4171a3b091700f94ae2746b8e47ca8632a7b2310030ad717645ec7cd:3.17.3",
    "operation": "mutation"
  },
  "contentblocksinteractionchangedsubscriptiondocument": {
    "hash": "e22c32f3dfdfa2b936f85a76844d925e2b49f5a1c0f0b7b6782f4227a2a99f70:3.17.3",
    "operation": "subscription"
  },
  "contentblockssortedsubscriptiondocument": {
    "hash": "4f6aabf7d2c0821b04a8133072222c64017a57978237d6c31f0e9f60bb26cdf6:3.17.3",
    "operation": "subscription"
  },
  "contentblocksupdatedsubscriptiondocument": {
    "hash": "7d4f603c972f7dd909ce46bf4552686b6cbe7aa47bad4c689f05113a6c5ae5ca:3.17.3",
    "operation": "subscription"
  },
  "contentblocksupdatemutationdocument": {
    "hash": "7c66e565413ce69b773181c36acc2b76917fd2940f62ff16e58557846f3f0dcc:3.17.3",
    "operation": "mutation"
  },
  "contentblockupdatemutationdocument": {
    "hash": "4182b7a2e998a20e7310c873817ba88e3240765a4bd269107e93a02949786697:3.17.3",
    "operation": "mutation"
  },
  "contentcreatevariantmutationdocument": {
    "hash": "1d92db6dc8f147f333d0b473100e4d02dd72c0b30c75743f5946bb17fd2b0a6a:3.17.3",
    "operation": "mutation"
  },
  "contentdataquerydocument": {
    "hash": "6c161c0fb985e116cdac5d58217e5059547784d2087c6715d16ef458f6ce8d33:3.17.3",
    "operation": "query"
  },
  "contentdeletedsubscriptiondocument": {
    "hash": "8d2b3f9a6e1e6fe6f5f1a096306a87feb8a9662f7ed2b134c7b07c1ac1c55bd2:3.17.3",
    "operation": "subscription"
  },
  "contentdeletemutationdocument": {
    "hash": "c763a3fddd0a50bc669165b62f860861ed54cc7235aeae95b5a3787319c024f8:3.17.3",
    "operation": "mutation"
  },
  "contententitiesmetaupdatedsubscriptiondocument": {
    "hash": "95a4e08f0ba47747c113dad04780deede309804182a1dd8ca86f27d41d6300fa:3.17.3",
    "operation": "subscription"
  },
  "contentlistquerydocument": {
    "hash": "43e26830d8e5616bb4a74290868058c9d87b5216c7a73effb0ca98b5dd70212f:3.17.3",
    "operation": "query"
  },
  "contentmergevariantmutationdocument": {
    "hash": "bf6602936e265e6ef66e9dfea08909721a556b70d409ee4e5b2e19cbff6a21ce:3.17.3",
    "operation": "mutation"
  },
  "contentmodulescreatedsubscriptiondocument": {
    "hash": "0b4a09ea0df2eff2cfb8297c6e25fcbfc6db2dd0f9fdf973bcbf31880b9b88d2:3.17.3",
    "operation": "subscription"
  },
  "contentmodulescreatemutationdocument": {
    "hash": "13ad079813c0df32e656d7ec0f21fe5422e35e8fbf1992e236a8671e8dcefb77:3.17.3",
    "operation": "mutation"
  },
  "contentmodulesdeletedsubscriptiondocument": {
    "hash": "16b09b8444e9b6a1710c78ebe6a968cae911fc2650addb5afb34e0add3f73117:3.17.3",
    "operation": "subscription"
  },
  "contentmodulesdeletemutationdocument": {
    "hash": "a9677d89cf919621d84498e474fda4408319e56f3e531a8266c6be282a4778bd:3.17.3",
    "operation": "mutation"
  },
  "contentmodulesduplicatemutationdocument": {
    "hash": "15ef5262ae646455960793cb0a3109dde67614f4a59131b72e3be99f07045863:3.17.3",
    "operation": "mutation"
  },
  "contentmodulesquerydocument": {
    "hash": "199ecf9de66e9f906024b72abe09c99d118a54c51fdb1db3bc6ac18a137dc7d7:3.17.3",
    "operation": "query"
  },
  "contentmodulesupdatedsubscriptiondocument": {
    "hash": "55f3b9de4101cb45886dc5a747d8f5cdde60ea3a4b774082e1df27fc51cb3087:3.17.3",
    "operation": "subscription"
  },
  "contentmodulesupdatemutationdocument": {
    "hash": "5f0de2d1ab79a0c089ba91c70d7b12df469ba5e940bc9dc9a6991be3cb2eff78:3.17.3",
    "operation": "mutation"
  },
  "contentonlineusersquerydocument": {
    "hash": "ff5964811cbc52ee9131761456e23c6388895a5e850aca5130d39a01241bb846:3.17.3",
    "operation": "query"
  },
  "contentonlineuserssubscriptiondocument": {
    "hash": "0f5eaaab73243a965f19c35b460daa74dfb0bcb8cae6850e601c527a57772635:3.17.3",
    "operation": "subscription"
  },
  "contentoverviewquerydocument": {
    "hash": "82e2ef0d591639a47bf3a3820f7e6a49ac9b6ee3647be4e608129b9e794a3853:3.17.3",
    "operation": "query"
  },
  "contentpublishedlistquerydocument": {
    "hash": "a314b72ffa935e0be8ed114e40d811237fba05959026f80e5e92b1d8ea097ee5:3.17.3",
    "operation": "query"
  },
  "contentsquerydocument": {
    "hash": "bae046f91895aab6545ee0013aa958eddebb78defbd06cddeb6e0af193e06902:3.17.3",
    "operation": "query"
  },
  "contentupdatedatquerydocument": {
    "hash": "58e787a53fce0f4a79e39f2d882cc3106262230617b8f6ff651a3e4297762d03:3.17.3",
    "operation": "query"
  },
  "contentupdatedsubscriptiondocument": {
    "hash": "25ef9a06d874b47d4d154681f47f11337764b481dff1d7a307911330a7be0bb6:3.17.3",
    "operation": "subscription"
  },
  "contentupdatemutationdocument": {
    "hash": "9c072a5495a68ebd80bc42c4d0c5a58b6bc32598353a124f2f76ea65d3337d0f:3.17.3",
    "operation": "mutation"
  },
  "contentversioncheckoverlimitquerydocument": {
    "hash": "02c9ea2d7ce7d1c7ae5416653e72853627d4a01a75a5845f186115b914d7321e:3.17.3",
    "operation": "query"
  },
  "contentversioncreatemutationdocument": {
    "hash": "1f4efacca0087180a1ce3dea31dfeb370b08ac04097e073308140bed0d2cefde:3.17.3",
    "operation": "mutation"
  },
  "contentversiondeletemutationdocument": {
    "hash": "1e308bd35deb4b743030308123490a6074b58cdd844247378e43b01f63ab738e:3.17.3",
    "operation": "mutation"
  },
  "contentversionrestoredocument": {
    "hash": "5d7e8bf016f671497a6d567c770953bb0d3403d4a7045ecfe891a386eb463403:3.17.3",
    "operation": "mutation"
  },
  "contentversionsquerydocument": {
    "hash": "1e5264d4722ab7dd9686dd2106973542a67c96f5bfaedbeed20e3802075ca3f2:3.17.3",
    "operation": "query"
  },
  "contentversionupdatemutationdocument": {
    "hash": "6bc60db2fb243e16d8c81933a28d8354f70d3e3b346a2d43934da679c60f3b85:3.17.3",
    "operation": "mutation"
  },
  "countrycodesquerydocument": {
    "hash": "00e9cbcfd5c59e237e8b311368c1c0552642db7a8ac8b1d56f53fe8049012024:3.17.3",
    "operation": "query"
  },
  "createpreviewbypathmutationdocument": {
    "hash": "bff2c623e5d9b8af38f7a471112ba5fb4047c2bc3d927f091029c7309b304e28:3.17.3",
    "operation": "mutation"
  },
  "createprojecteditormutationdocument": {
    "hash": "e6eb6715d6f3618d85050e3a194efa9f1e7ac21d33dc31736c2ead89279c7f00:3.17.3",
    "operation": "mutation"
  },
  "createprojectuserwithpermissionsdocument": {
    "hash": "474891f49827807b1daf93bf1dbb6391db9fd06ec7f5ace899bd67523cbfe336:3.17.3",
    "operation": "mutation"
  },
  "createprojectviewermutationdocument": {
    "hash": "735541b732e00f90c332dfafcf98807e0ece26d3ce09e9a7cb87c26d637fe523:3.17.3",
    "operation": "mutation"
  },
  "createsnippetmutationdocument": {
    "hash": "3f07522ad9850d98d814f06e18285bf6eba78e79c0b6e07a2e34430de2c01110:3.17.3",
    "operation": "mutation"
  },
  "deletecommentmutationdocument": {
    "hash": "68f464b2a9ed94ef5e314963a15399dd0a3241447be632dea69a3b11493c6ded:3.17.3",
    "operation": "mutation"
  },
  "deletereusablecontentblockdocument": {
    "hash": "8b7c29d910360ee313cee4bfdea5f75cba912fbf60300486911c0fdb48cd962c:3.17.3",
    "operation": "mutation"
  },
  "deletesnippetmutationdocument": {
    "hash": "0c6b3d7d0dd60df89db0bd5ef6a432cb7a7a72692099d6250fb9bd55086adbbd:3.17.3",
    "operation": "mutation"
  },
  "dependencyforquerydocument": {
    "hash": "cdbfc9398f7ae502b1dda81c8f36b026b7d088d9a8b56780cb6c9c303b5d1da5:3.17.3",
    "operation": "query"
  },
  "deploymentquerydocument": {
    "hash": "6ea57852f554c7e41626f26855df460ae07c2f1f13e6000e027e553c74d4497e:3.17.3",
    "operation": "query"
  },
  "devtoolsquerydocument": {
    "hash": "f9789b20adc5b7f5ad47e62f4eb7bf384d13e9ea74e6d0ab017553ee9c32c493:3.17.3",
    "operation": "query"
  },
  "discardsnippetmutationdocument": {
    "hash": "46af36d6d866cae9b1e23035be5c006515ee15200cf69129e5d389af861987b4:3.17.3",
    "operation": "mutation"
  },
  "domainenvironmentquerydocument": {
    "hash": "6f7096fb6c15fb348c03e282701431e815b514b39f3c918e112fb966c1c2193b:3.17.3",
    "operation": "query"
  },
  "domainsdetailquerydocument": {
    "hash": "f62262706a09be94669938ae3a185b7babe4cf88a23d8ccdd1d5d6ff4447b4df:3.17.3",
    "operation": "query"
  },
  "dynamiccontentparentpathsquerydocument": {
    "hash": "7af70620a28ab4733accaed54d06f6df070098ad00e456f77e3da3c12547317d:3.17.3",
    "operation": "query"
  },
  "dynamiccontentquerydocument": {
    "hash": "8f64c0a247c8ee8b4628683f750ded56049395d08c270e7e2f24a31300d9068e:3.17.3",
    "operation": "query"
  },
  "dynamicpathsbyrootidsdocument": {
    "hash": "53dce953b2962dea14a79424611291353ab6d73b4c9784f04949e1c59ca0716c:3.17.3",
    "operation": "query"
  },
  "executedeploymentmutationdocument": {
    "hash": "f36088605be4f413ecf46f43bfe87c79bab4a6adea39e798a97043243f86f18b:3.17.3",
    "operation": "mutation"
  },
  "fontcreatedsubscriptiondocument": {
    "hash": "b5e0de2d4a998ea25c1c5de3ff9bf40c9ebb65a8194efa1f3b4539330624a7aa:3.17.3",
    "operation": "subscription"
  },
  "fontdeletedsubscriptiondocument": {
    "hash": "867a998bf850128112c15cae59ff15cb4c15c50f3cb7246706524e51ceaad2eb:3.17.3",
    "operation": "subscription"
  },
  "fontscdnintegrityquerydocument": {
    "hash": "310d926a234084e1f37a79573591354ec06fad62a33c7ae69befd0af6880a0af:3.17.3",
    "operation": "query"
  },
  "fontstylebyidquerydocument": {
    "hash": "8504e6c33068bb3019c05cfaea3ea0740834bb84b675d5e7d2e731e03f57cb44:3.17.3",
    "operation": "query"
  },
  "fontstylecreatemutationdocument": {
    "hash": "39697396eebc90c4c6c892823aee93544795e81fb49f10f8b70b1bc348962c46:3.17.3",
    "operation": "mutation"
  },
  "fontstyledeletemutationdocument": {
    "hash": "81841a75de61e34c544b032cb048ae4307173694e3cc5bbae4817b34641519be:3.17.3",
    "operation": "mutation"
  },
  "fontstylesquerydocument": {
    "hash": "7276fefa5f424b7fb03e13251f5e52b397276d9c644330872731696dbded5d51:3.17.3",
    "operation": "query"
  },
  "fontstyleupdatemutationdocument": {
    "hash": "c00fd064ee76c17c7c2c996e0dc00eab816232498bc8d7a43f36672503979189:3.17.3",
    "operation": "mutation"
  },
  "fontupdatedsubscriptiondocument": {
    "hash": "405f0d67edfb6c41097c339d8e20c0e2ea30fa543040f5824a4397d0b2038d2f:3.17.3",
    "operation": "subscription"
  },
  "historyquerydocument": {
    "hash": "9f13441211d302db399b5bace4b07d089c856d6f141a237b4b5fe82205f31daa:3.17.3",
    "operation": "query"
  },
  "issuedsnippetdependencyforquerydocument": {
    "hash": "3463afada38fc39709bf0a6de80c7a207a7d2eeabb1bef62316b527946907305:3.17.3",
    "operation": "query"
  },
  "issuesnippetmutationdocument": {
    "hash": "64aa6d4c3eaf142fca776813b1f009e167ddbecfa2717d13699a8e69376e2b36:3.17.3",
    "operation": "mutation"
  },
  "keepalivemutationdocument": {
    "hash": "358945059981997bb822624d46d62385028c47130e7304c6fc16b0f8e596c020:3.17.3",
    "operation": "mutation"
  },
  "languagesquerydocument": {
    "hash": "e5f6e4fe73a9738943c7dd081cf315adacb94bb7d2b6062c4cb5803ec3b56d85:3.17.3",
    "operation": "query"
  },
  "loginemailmutationdocument": {
    "hash": "86bc11baea24855fe4017239e039916b63e68bb6d011fdfff3dca2cab1884d67:3.17.3",
    "operation": "mutation"
  },
  "logingooglemutationdocument": {
    "hash": "8989a27f77f51de660e8089a61e2a1d367f53082dcbafaa79d968866a5688195:3.17.3",
    "operation": "mutation"
  },
  "logintokenmutationdocument": {
    "hash": "6e06fa0b8998297f24f9c06ae1ea33a5ca76e5e2db810d44d836fe3653fd211d:3.17.3",
    "operation": "mutation"
  },
  "makehomepagedocument": {
    "hash": "83fefd1fd3f3f8a44f3bb22da0bd81275bf624c1d7fb728f4d2e95476fc62cee:3.17.3",
    "operation": "mutation"
  },
  "menucreatemutationdocument": {
    "hash": "4d2ae70972877ec3aecb9015e8c765d128e6e3a641dbd34e51ac96e8671595fe:3.17.3",
    "operation": "mutation"
  },
  "menuupdatemutationdocument": {
    "hash": "e4589993644e725d51a2982f94aad3ccda2a866317bdb4304c61f3563dc07c2b:3.17.3",
    "operation": "mutation"
  },
  "mergereusablecontentblockdraftdocument": {
    "hash": "4b5dd73b0f6fea66ba766a6db5d63553336f675e6a42d1c2516de8f559fe44f3:3.17.3",
    "operation": "mutation"
  },
  "pathavailabilityquerydocument": {
    "hash": "c21c73251dccb15afb70937a98afa020dccaf8fa8a8003bff0dcf6ea6ffa686f:3.17.3",
    "operation": "query"
  },
  "pathbyidquerydocument": {
    "hash": "ccb39f70e8600870d340d253f4e098cf994b2c3ed01408f3d320731081bea540:3.17.3",
    "operation": "query"
  },
  "pathcontentquerydocument": {
    "hash": "1aa5e1082eb1dec972889039ae78f58c8e91cb11e126f36639eae87900d58475:3.17.3",
    "operation": "query"
  },
  "pathcreatetypecontentmutationdocument": {
    "hash": "34a5a2afc2b5434ed316d2fbdee8229f3ea5550b8562e08a01949ab9757f45ca:3.17.3",
    "operation": "mutation"
  },
  "pathcreatewithclonedcontentmutationdocument": {
    "hash": "195df2260953ab1dd675bc25f8057c523a7cedea32ab8eeed1b1b23a066d163c:3.17.3",
    "operation": "mutation"
  },
  "pathcreatewithexistingcontentmutationdocument": {
    "hash": "16a8ff2460371428e7c11c25d8919e201170c10c5d78f79468b8c736da03e8e2:3.17.3",
    "operation": "mutation"
  },
  "pathdeletemutationdocument": {
    "hash": "d696f912d13b1e2c2f8a3939c2855734fb2a22d4d9e4889e5f15e55484f73a53:3.17.3",
    "operation": "mutation"
  },
  "pathinfoquerydocument": {
    "hash": "4d3eafac6fa8c4495fac06d4e682c1cbd577d26bd96c501e04b6e0e41949c745:3.17.3",
    "operation": "query"
  },
  "paths404querydocument": {
    "hash": "46de23986e34b3d6633c0a003bb62bcf2c6941332bc4097d2c6893dd7b1692b1:3.17.3",
    "operation": "query"
  },
  "pathsetcanonicalmutationdocument": {
    "hash": "3d82b6c19cfcc5d4372b01fe5a76ba0989380c4799c06e50701b3ad31e8a53d4:3.17.3",
    "operation": "mutation"
  },
  "pathsetprotectionmutationdocument": {
    "hash": "c115bbd3e422c0d5d5054f9c130f2736403f6f53c0b619af3e10fbd9c388d663:3.17.3",
    "operation": "mutation"
  },
  "pathslistquerydocument": {
    "hash": "c214e018b8ef23547bae9d7ca79a05247bc6e8de1f07a65e6a32e78b565198ba:3.17.3",
    "operation": "query"
  },
  "pathsmaintenancequerydocument": {
    "hash": "90f6cbe8ff8e006429402d39f60e5eeb20995ef12afa32599500285f454cf129:3.17.3",
    "operation": "query"
  },
  "pathspublishstatequerydocument": {
    "hash": "de6182dd2d368bcc5b9e750de5d7a96ae57dbb69a9bae29a05595a2eef03ca3f:3.17.3",
    "operation": "query"
  },
  "pathupdatemutationdocument": {
    "hash": "ca7d6382d78ac9f7b068a980881fab2481e6d0514a4b3537accbc14157cd6ffb:3.17.3",
    "operation": "mutation"
  },
  "processstripewebhookmutationdocument": {
    "hash": "4c7c39268a5ceff28b9ba8b3bcd44a60887dc5a33c7b9323b16753ba7dd9f040:3.17.3",
    "operation": "mutation"
  },
  "projectarchivemutationdocument": {
    "hash": "f2e7402ec43b5cd1a642881f7bed70cd715f3a7fd60be3f588e1ca1e6de6c0de:3.17.3",
    "operation": "mutation"
  },
  "projectassigndomainmutationdocument": {
    "hash": "9878bbcc8ac0389eec4a7e830e60d062b5e616220100c83c89af21013e0b9398:3.17.3",
    "operation": "mutation"
  },
  "projectassignedandsystemusersquerydocument": {
    "hash": "e9ef64241e163fea0115e99b47fd918daf41bc901242d16f7f0a85643f36fd88:3.17.3",
    "operation": "query"
  },
  "projectassignedsystemusersquerydocument": {
    "hash": "20bf829ca6e5214c7dbce93d43a61bf66524a4f87ba0376d7b0a5c5bd3152e29:3.17.3",
    "operation": "query"
  },
  "projectassignedusersquerydocument": {
    "hash": "da43687aa2d7036faaa438a67f944345e3b1c4c6d5b6b95d4d1b84ad80dc9d2b:3.17.3",
    "operation": "query"
  },
  "projectauthlinkdisablemutationdocument": {
    "hash": "c22e3c03d9991ce27728b263740d1553f1fa6f4e6b7b74cfca9b7519f2a40b35:3.17.3",
    "operation": "mutation"
  },
  "projectauthlinkenablemutationdocument": {
    "hash": "6ac6dfb0d5082d56818d0091b0076687a7ad52f7a27e799f85970fe0c89f05d3:3.17.3",
    "operation": "mutation"
  },
  "projectclonemutationdocument": {
    "hash": "35cea75f35a990a723f74e68101658aaf4dc9d07ecf80b3727e223cc92d1d5c0:3.17.3",
    "operation": "mutation"
  },
  "projectcreatemutationdocument": {
    "hash": "cd2bc6ed5619b75d57c579edf7cfcd06502e6d91cc37a4c770fcfb108d5e9905:3.17.3",
    "operation": "mutation"
  },
  "projectdeletemutationdocument": {
    "hash": "b06dd12ed8eb6f1be82310b4496642bfc12d72298ffec9f53e5d44bdecf4916e:3.17.3",
    "operation": "mutation"
  },
  "projectpreferencesquerydocument": {
    "hash": "13eda80e8cd043a12e68586907a0b63ab69b8382d554d82dd0d167294ea5b29f:3.17.3",
    "operation": "query"
  },
  "projectquerydocument": {
    "hash": "7d748fde939b03ae9003e5fd9852a1ab3f816098ab50e0bc9109b69666eeb35f:3.17.3",
    "operation": "query"
  },
  "projectsiteplansubscribemutationdocument": {
    "hash": "1466f3a98e04276f84a975a67af365fb47316378692ae494ab225c32bc6c9484:3.17.3",
    "operation": "mutation"
  },
  "projectsiteplanunsubscribemutationdocument": {
    "hash": "8907863201995f5afcd3d8cb975dbef1fdb4e427a5d2cb8c830f515192e0b39f:3.17.3",
    "operation": "mutation"
  },
  "projectswapactivearchivemutationdocument": {
    "hash": "e2ab8570eea03c9fe891e5598b3327fa29e12347be07e2154e677e6793dae8a4:3.17.3",
    "operation": "mutation"
  },
  "projectunarchivemutationdocument": {
    "hash": "fb0f7b66c8a1f554b24f41d4a5a1f88c27f1a7b7b66ee00cf481d9b16e7ddc5e:3.17.3",
    "operation": "mutation"
  },
  "projectupdatenamemutationdocument": {
    "hash": "d6fe541cd0166643e2a13e180a72ffbabacf8fe3da144daf22507a1d63a1e99b:3.17.3",
    "operation": "mutation"
  },
  "projectupdatesettingsmutationdocument": {
    "hash": "10209330a53b3c4aa21af6c05422f3646ac381ef79d1492fd8ec4ddbfee4d560:3.17.3",
    "operation": "mutation"
  },
  "projectworkspacequerydocument": {
    "hash": "be253358fe41bf415712d13f8f800940b858f7dd56d48e7aad1ad8b6ea28096e:3.17.3",
    "operation": "query"
  },
  "publicpathsquerydocument": {
    "hash": "b1ff6cbb60d4269113d236bfc35d8b2518a62d2208b68e5aa2ecf4c876eb60ac:3.17.3",
    "operation": "query"
  },
  "publishedcontentquerydocument": {
    "hash": "26a680625e4addc44ef2e50032dac77da82d9966c4b7010cc948c73a1ea3da40:3.17.3",
    "operation": "query"
  },
  "redirectcreatemutationdocument": {
    "hash": "984176d245df4f6bbf0567216890935021f57e99c14679b96e04a937d402a870:3.17.3",
    "operation": "mutation"
  },
  "redirectdeletemutationdocument": {
    "hash": "e30c4cc4a3051ea00adb5f5348b25fba540686e1ee2ad05ec0a724f331af886f:3.17.3",
    "operation": "mutation"
  },
  "redirectsquerydocument": {
    "hash": "6824e3f27c42487798caf539989346f66efe57fea80510da0450fb5c36a5ecc6:3.17.3",
    "operation": "query"
  },
  "redirectupdatemutationdocument": {
    "hash": "582307f0c4250dbcca67b35e42215eee8701733ed2e155d2b05f5d2d2d7290c9:3.17.3",
    "operation": "mutation"
  },
  "removeworkspaceavatarmutationdocument": {
    "hash": "54de2fed9e40a3a2fc23f0b48093af363a221a753f520ac368fca3dafc5a6da4:3.17.3",
    "operation": "mutation"
  },
  "renamereusablecontentblockdocument": {
    "hash": "7e59b5ed88b428c464c3ee37cfb42bc66ada65092085269abbb92a057777f5a6:3.17.3",
    "operation": "mutation"
  },
  "replacecontentdatawithexistingcontentdocument": {
    "hash": "e0b0756aafa949df91766e95d636a44242b447fe7ac6975496b087d6fb72b786:3.17.3",
    "operation": "mutation"
  },
  "requestcontentblocksbyaiv2document": {
    "hash": "9a7d0564ca7f750c89ca312504a101da6e5eeaffaf0bb8d99e7855422d08f25e:3.17.3",
    "operation": "mutation"
  },
  "resourcebyslugquerydocument": {
    "hash": "e939c1c2b2de2befc16580cc11d1bb5da8e2ce6998cd8969a7b9098ff08e559b:3.17.3",
    "operation": "query"
  },
  "resourcecreatemutationdocument": {
    "hash": "feeda94490dd76fd5e6da78f87a0202d348aaefcc0ccb09353c781606bf97b6c:3.17.3",
    "operation": "mutation"
  },
  "resourcedeletemutationdocument": {
    "hash": "af6ae75df06aba6661b8435b271de988105978bbef47738e1ddf247ef718e717:3.17.3",
    "operation": "mutation"
  },
  "resourcesquerydocument": {
    "hash": "3204a40f67a41ed3b302edc2fc57ae24f471a4687a6e3080e0ef4df82940ae2d:3.17.3",
    "operation": "query"
  },
  "resourceupdatemutationdocument": {
    "hash": "379ad843d2d1f7c0f835c59efd5322e694d921eac1f5d64952fd272a07bd9c35:3.17.3",
    "operation": "mutation"
  },
  "reusablecontentblockassignmentsdocument": {
    "hash": "ce816d98abc301282d9a9aefc0367fb09a6c6619e775d3b8a9b2f4b9bb7eeb10:3.17.3",
    "operation": "query"
  },
  "reusablecontentblocksquerydocument": {
    "hash": "19ff4d9e58a44cf037dfd0e1863fb438d2044408fd4709fc0a3acec40a2a353a:3.17.3",
    "operation": "query"
  },
  "reusablecontentblocksupdatedsubscriptiondocument": {
    "hash": "e04fc6e118230a00d9eb34d3e496e2b0f09b4095c8d853dd714264ced6bfab84:3.17.3",
    "operation": "subscription"
  },
  "reusablecontentblocksupdatemutationdocument": {
    "hash": "3699e6e04beebfdbc778ab47bb098e6ef30e141c8b3115bc8c34d8f5cbd91b57:3.17.3",
    "operation": "mutation"
  },
  "rootpathquerydocument": {
    "hash": "ade734065291bbfcbbf57d43fa6af2cd6e54087889da5651fd4289b7fe48b94d:3.17.3",
    "operation": "query"
  },
  "routeresolverpathsquerydocument": {
    "hash": "fdfa9474f6c559f4889ef0dbb9099ea014e86317c139ce8abb9ef13de2a596a2:3.17.3",
    "operation": "query"
  },
  "scrapepagemetadocument": {
    "hash": "99e4ad633a82dd8c24dac1bb9b90fd8020c5ed3780747b9f5c3ac5df9c9a9f41:3.17.3",
    "operation": "query"
  },
  "setasnonrenderablecontentblocksmutationdocument": {
    "hash": "526474cac20a1673f2c21a34459d3e4ceacd36b90c77796a6f7c0c6ba8f32f81:3.17.3",
    "operation": "mutation"
  },
  "setasrenderablecontentblocksmutationdocument": {
    "hash": "49796b684e444bc3b44ed9212bdeec760307ada76c0155517c6efd25fdf669cc:3.17.3",
    "operation": "mutation"
  },
  "setasreusablecontentblockdocument": {
    "hash": "cd755620ac2643f611b7a84247a2f0ba8be72e1b471ef6765b9e374bbc4f93ec:3.17.3",
    "operation": "mutation"
  },
  "setinteractiononcontentblockdocument": {
    "hash": "935254a70812e96b0bdce7ce776d920e11c1bd2bfa2ed9d54ab5e514c54ca96d:3.17.3",
    "operation": "mutation"
  },
  "snippetbyidquerydocument": {
    "hash": "945333a100a10e0275c7e0a03c4dad2219449c6d173aaee4c9f9da26d437d6c5:3.17.3",
    "operation": "query"
  },
  "snippetcreatedsubscriptiondocument": {
    "hash": "88fe79708beef980b213845815f8e56cd0319eeba32f34176ee27c46f0204e77:3.17.3",
    "operation": "subscription"
  },
  "snippetdeletedsubscriptiondocument": {
    "hash": "1424ee31cc82497bb3b2410e4ed2e603372c8e44c906a5425cc6c72aeea89baf:3.17.3",
    "operation": "subscription"
  },
  "snippetdiscardedsubscriptiondocument": {
    "hash": "efdd0c45c4b3ec4b610cf997e6ecbaa17647216e4cb491bee2bd8829f8e01796:3.17.3",
    "operation": "subscription"
  },
  "snippetissuedsubscriptiondocument": {
    "hash": "e2a5fce9a8d3f2aec5d59398f374151f3beca0208ce93f916f5a94c6fa1ce0a1:3.17.3",
    "operation": "subscription"
  },
  "snippetsdataquerydocument": {
    "hash": "0e6dbe4a15c18c37d12a1790db4b8eb504c81e9b4bc04ae3d8012cc74f7a47b7:3.17.3",
    "operation": "query"
  },
  "snippetsupdatedsubscriptiondocument": {
    "hash": "3cb86ffa490cc90b9be3625a3947e702e4217884c6065a476940b2f2ef8ca8d5:3.17.3",
    "operation": "subscription"
  },
  "stripeportalsessioncreatemutationdocument": {
    "hash": "8d78db70019dbe790e10ea11b03b9291011202e241c735530d0bff532df7ad72:3.17.3",
    "operation": "mutation"
  },
  "subscriptionaddonsquerydocument": {
    "hash": "4983c9f18d785c93163bb3b527cafeaed3bd3c2d166c583c833149e2d93ff73f:3.17.3",
    "operation": "query"
  },
  "subscriptionslimitsbyworkspaceidquerydocument": {
    "hash": "6c578a4cb231cfe39026926cdaeb4d16558dedf8ea245d73b27e6256734ba37a:3.17.3",
    "operation": "query"
  },
  "subscriptiontrialextendmutationdocument": {
    "hash": "11dc1ddf6f404a5f7cfff92aa101cc9b8aef5a57df7e8a7149823f516a7f0e46:3.17.3",
    "operation": "mutation"
  },
  "suggestprojectinternaldomainsdocument": {
    "hash": "b23d6d6c335dd99d1ceed95f03d23f587b12c2f4a4903cf3da9ff7249da62930:3.17.3",
    "operation": "query"
  },
  "sysgetintercomuserhashdocument": {
    "hash": "096f70f664b6754222264cdf77f3db8b9377fb5b40049e141ca3cad7880c949f:3.17.3",
    "operation": "query"
  },
  "sysgetwebusertokenquerydocument": {
    "hash": "3645c3740cfe69950adca90328949477a3547f3285159d383043e54cfcb1d3e3:3.17.3",
    "operation": "query"
  },
  "templateslistquerydocument": {
    "hash": "276c1bf172e80c12ee388dad1d497bddd50913da4d7d8f4c3e034e398943d78a:3.17.3",
    "operation": "query"
  },
  "templatesquerydocument": {
    "hash": "ce483a16f4821527bc32e974f68cf2466b24c1c40d6fe7d8b406a62efd7a4af7:3.17.3",
    "operation": "query"
  },
  "triggercustomeventmutationdocument": {
    "hash": "c2b3efd5df550e348ad97c058920c2ea513ff3bd47a60445dcd6011ed2ca6842:3.17.3",
    "operation": "mutation"
  },
  "typeformgetformbyidquerydocument": {
    "hash": "bcb9ff088b371e30339f671fcfbf39672085d48fe6f32c03aa11a815ec56942d:3.17.3",
    "operation": "query"
  },
  "typeformgetformsquerydocument": {
    "hash": "d30387e1ea453da3efaef2d7db7026bc56073fce8e3a2afc85ad992befdf0a67:3.17.3",
    "operation": "query"
  },
  "updatecontentdatadocument": {
    "hash": "6adb0aadde62a01cdc9dd6973867073e91da0b1d213363e9540355878ebfad2a:3.17.3",
    "operation": "mutation"
  },
  "updatesnippetmutationdocument": {
    "hash": "3a14e7b6d9f6a16cf091c34a1ee022214c1d1903f780ff961c9cab89739fb72e:3.17.3",
    "operation": "mutation"
  },
  "updatesnippetsmutationdocument": {
    "hash": "96e8832916dab57347ee0de2b6c895e30910ab2e6e15b2d21f1a1d9dc00c2b42:3.17.3",
    "operation": "mutation"
  },
  "updateusermutationdocument": {
    "hash": "5f71b5b987462029a502023478246f2ebdc1a941eea6d8d03d0eed11c9c74deb:3.17.3",
    "operation": "mutation"
  },
  "updateuserprojectrolemutationdocument": {
    "hash": "8e8c3a4a5c5df1da0a317aa8a808e2a71841466c303de9cdb1e64a51d4e85075:3.17.3",
    "operation": "mutation"
  },
  "uploadmutationdocument": {
    "hash": "78e3bdf5c1d453344637d3cd63732b4aa78a9772646507579ee198fdabd866f0:3.17.3",
    "operation": "mutation"
  },
  "uploadworkspaceavatarmutationdocument": {
    "hash": "7d7332b1a992b00315ab029301a1636c996736e5519c9bc810f36f177f387c08:3.17.3",
    "operation": "mutation"
  },
  "useractiveprojectbyworkspaceiddocument": {
    "hash": "b41daf65e78a4b58a4c01203871e7e9081e4f464e5cf91e6f5b897509061d5ae:3.17.3",
    "operation": "query"
  },
  "useradditionalquerydocument": {
    "hash": "f55bc983c8580832de70b5fcd0ea3c2ed8701136b4f3c3268aa7c86c7abb9275:3.17.3",
    "operation": "query"
  },
  "userarchivedprojectsbyworkspaceiddocument": {
    "hash": "70c7004b7cda29a70dab71523924b5652477e2723d2d8ab6086cdb73ae5e800c:3.17.3",
    "operation": "query"
  },
  "userassignedprojectsbyworkspaceiddocument": {
    "hash": "52d9e000da7a5cec2b8e32a2f3521e4537a921ea872a56c0e11bdb53e61bc897:3.17.3",
    "operation": "query"
  },
  "userassignedprojectsdocument": {
    "hash": "b5731eeab8c369c252a7e7b5aa275499cf10ffaad834cc23980d7cff31fe9e4b:3.17.3",
    "operation": "query"
  },
  "userassignedprojectssearchbyiddocument": {
    "hash": "627ef445b25ec7ea8e73052763fa0d588334dd283d72cdbcae64a1aafcb76164:3.17.3",
    "operation": "query"
  },
  "userassignedprojectssearchbynamedocument": {
    "hash": "043ba7e26e6f0b8670868421ad5b21fcf655fc335fe949753e6f9dc1c901ba65:3.17.3",
    "operation": "query"
  },
  "userassignedworkspacebyiddocument": {
    "hash": "7bd9f52bd791ce3dc0c3e8a4f3a6cf3913c64cb2ba8d93dcaec775882a5ffcae:3.17.3",
    "operation": "query"
  },
  "userassignedworkspacesdocument": {
    "hash": "8b5a51c00f3f78fbbd01b27499e14d88b27ea2e61fbd8648ac5f05e8e774d49a:3.17.3",
    "operation": "query"
  },
  "userassignedworkspacessearchbyiddocument": {
    "hash": "923c8beb9e0bc34e569016e5d6ae9421e9364ea6ee2d80b3ceec5f0da8ffa41d:3.17.3",
    "operation": "query"
  },
  "userassignedworkspacessearchbynamedocument": {
    "hash": "d12e578faa93ecbe9593fdb8d8f362ce0423788015109f525d2dcb2f815fa9ad:3.17.3",
    "operation": "query"
  },
  "userownedworkspacesdocument": {
    "hash": "51c34d95995993fdc78edd8e4b7ae84bc4184f6acf201efa43623b137f2c9829:3.17.3",
    "operation": "query"
  },
  "userrecentprojectsdocument": {
    "hash": "9392a96a642233c1ebd5a69d5210f4159bfdf4a72de748b166842188b2920095:3.17.3",
    "operation": "query"
  },
  "usersegmentationquerydocument": {
    "hash": "bf86f743a7000f92704b64ce1d13ca2dcdd4090a35e7d7b6ccc571894a37a76e:3.17.3",
    "operation": "query"
  },
  "usersharedwithmeprojectsdocument": {
    "hash": "27c2a30c08f329e0ed6a119af338ce3ea007222e772dfe52e5395e3277374347:3.17.3",
    "operation": "query"
  },
  "userunassignmutationdocument": {
    "hash": "31b943675c1f85a6f0ac8f187e9b2d60c1f63196ab387206b5cccf1d40127bd5:3.17.3",
    "operation": "mutation"
  },
  "versionquerydocument": {
    "hash": "3eaaafd04af01ec036ba95964584a148c2b56d9f7a7f763d8e17bb3b3a165d63:3.17.3",
    "operation": "query"
  },
  "whiteboardcontententitiesdeletemutationdocument": {
    "hash": "91728c9a087c16f6fd279a946eafbb593d6e51d59bdabc1d2d16b9509290bf0c:3.17.3",
    "operation": "mutation"
  },
  "whiteboardcontententitiesmetaupdatemutationdocument": {
    "hash": "c3f1aa72d97ac22f45fda28621ac3587b1993c48490b9f5126832d324b033764:3.17.3",
    "operation": "mutation"
  },
  "workspacemanageeditorsmutationdocument": {
    "hash": "39726d735c1a8aa54c9e23d2f37ada4b05ac7ace6c859d033bdeb8a5b54a1dd0:3.17.3",
    "operation": "mutation"
  },
  "workspacemanageviewersmutationdocument": {
    "hash": "112fca732275445048e20c926c9f739d371e15fdc174427a78da58d80cb6d9c3:3.17.3",
    "operation": "mutation"
  },
  "workspaceplansubscribemutationdocument": {
    "hash": "e61c425928839f83f80bb1c389c47e9f8d8c46fba14ca686257faddc807f8132:3.17.3",
    "operation": "mutation"
  },
  "workspaceunassignusersmutationdocument": {
    "hash": "617b85e06294ac364dfefaf7ca287e2bc7f9b5ade709a4f08a53ae642446398c:3.17.3",
    "operation": "mutation"
  },
  "workspaceupdatedsubscriptiondocument": {
    "hash": "c9993dc8984ca103b6064fa7e17b61a739e24c1216112572698ad6098fb04249:3.17.3",
    "operation": "subscription"
  },
  "workspaceupdatemutationdocument": {
    "hash": "75a4d8c5ec3cbe2f657f21502966dd06a09a18b6e12abf181ad8895387358c1f:3.17.3",
    "operation": "mutation"
  }
}